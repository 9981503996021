
    document.addEventListener("DOMContentLoaded", (e) => {
        setVideoSources = (e) => {
            // id of video to target
            const lazyLoadVideo = document.getElementById("FSL_FA23_MFT_Video_Dive");

            // video urls
            const urlsMobile = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_0905_LP_MFT_Hero2_Mobile_video-med"]; //
            const urlsMobileHD = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_0905_LP_MFT_Hero2_Mobile_video-hires2"]; //
            const urlsDesktop = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_0905_LP_MFT_Hero2_Desktop_video-med"];
            const urlsDesktopHD = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_0905_LP_MFT_Hero2_Desktop_video-hires2"];

            // media match points
            let desktopMinMedia = window.matchMedia("(min-width: 768px)");
            let desktopMaxMedia = window.matchMedia("(max-width: 1919px)");
            let desktopHDMinMedia = window.matchMedia("(min-width: 1920px)");

            // if the screen width is above 1920px (desktop hd)
            if (desktopHDMinMedia.matches) {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsDesktopHD[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsDesktopHD[i]);
                    }
                    lazyLoadVideo.load();
                }

                // if the screen width is between 768px and 1919px (desktop)
            } else if (desktopMinMedia.matches && desktopMaxMedia.matches) {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsDesktop[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsDesktop[i]);
                    }
                    lazyLoadVideo.load();
                }

                // if either condition above is not met, default to mobile
            } else {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsMobile[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsMobile[i]);
                    }
                    lazyLoadVideo.load();
                }
            }
        };

        /* load event - sets data-srcs of video tag */
        setVideoSources(e);

        /* resize event - set <video> <source> tag(s) "src" attributes */
        window.addEventListener("resize", (e) => {
            setVideoSources(e);
        });

        const lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

        // if ("IntersectionObserver" in window) {
        //     const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
        //         entries.forEach(function (video) {
        //             if (video.isIntersecting) {
        //                 for (let source in video.target.children) {
        //                     const videoSource = video.target.children[source];
        //                     if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
        //                         videoSource.src = videoSource.dataset.src;
        //                     }
        //                 }

        //                 video.target.load();
        //                 video.target.classList.remove("lazy"); 
        //                 lazyVideoObserver.unobserve(video.target);
        //             }
        //         });
        //     });

        //     lazyVideos.forEach(function (lazyVideo) {
        //         lazyVideoObserver.observe(lazyVideo);
        //     });
        // }
    });


    document.addEventListener("DOMContentLoaded", (e) => {
        setVideoSources = (e) => {
            // id of video to target
            const lazyLoadVideo = document.getElementById("FSL_FA23_MFT_Video_Raquel");

            // video urls
            const urlsMobile = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_0905_LP_MFT_Hero4_Mobile_video-med"]; //
            const urlsMobileHD = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_0905_LP_MFT_Hero4_Mobile_video-hires2"]; //
            const urlsDesktop = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_0905_LP_MFT_Hero4_Desktop_video-med"];
            const urlsDesktopHD = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_0905_LP_MFT_Hero4_Desktop_video-hires2"];

            // media match points
            let desktopMinMedia = window.matchMedia("(min-width: 768px)");
            let desktopMaxMedia = window.matchMedia("(max-width: 1919px)");
            let desktopHDMinMedia = window.matchMedia("(min-width: 1920px)");

            // if the screen width is above 1920px (desktop hd)
            if (desktopHDMinMedia.matches) {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsDesktopHD[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsDesktopHD[i]);
                    }
                    lazyLoadVideo.load();
                }

                // if the screen width is between 768px and 1919px (desktop)
            } else if (desktopMinMedia.matches && desktopMaxMedia.matches) {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsDesktop[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsDesktop[i]);
                    }
                    lazyLoadVideo.load();
                }

                // if either condition above is not met, default to mobile
            } else {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsMobile[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsMobile[i]);
                    }
                    lazyLoadVideo.load();
                }
            }
        };

        /* load event - sets data-srcs of video tag */
        setVideoSources(e);

        /* resize event - set <video> <source> tag(s) "src" attributes */
        window.addEventListener("resize", (e) => {
            setVideoSources(e);
        });

        const lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

        // if ("IntersectionObserver" in window) {
        //     const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
        //         entries.forEach(function (video) {
        //             if (video.isIntersecting) {
        //                 for (let source in video.target.children) {
        //                     const videoSource = video.target.children[source];
        //                     if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
        //                         videoSource.src = videoSource.dataset.src;
        //                     }
        //                 }

        //                 video.target.load();
        //                 video.target.classList.remove("lazy"); 
        //                 lazyVideoObserver.unobserve(video.target);
        //             }
        //         });
        //     });

        //     lazyVideos.forEach(function (lazyVideo) {
        //         lazyVideoObserver.observe(lazyVideo);
        //     });
        // }
    });


    document.addEventListener("DOMContentLoaded", (e) => {
        setVideoSources = (e) => {
            // id of video to target
            const lazyLoadVideo = document.getElementById("FSL_FA23_MFT_Video_Campaign");

            // video urls
            const urlsMobile = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_MASTER_MADE_FOR_THIS_CROSS_CAT_30_1080x1080_GLOBAL_EN_video-med"]; //
            const urlsMobileHD = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_MASTER_MADE_FOR_THIS_CROSS_CAT_30_1080x1080_GLOBAL_EN_video-hires2"]; //
            const urlsDesktop = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_MASTER_MADE_FOR_THIS_CROSS_CAT_30_1920x1080_GLOBAL_EN_video-med"];
            const urlsDesktopHD = ["https://fossil.scene7.com/is/content/FossilPartners/FSL5686125_FA23_MASTER_MADE_FOR_THIS_CROSS_CAT_30_1920x1080_GLOBAL_EN_video-hires2"];

            // media match points
            let desktopMinMedia = window.matchMedia("(min-width: 768px)");
            let desktopMaxMedia = window.matchMedia("(max-width: 1919px)");
            let desktopHDMinMedia = window.matchMedia("(min-width: 1920px)");

            // if the screen width is above 1920px (desktop hd)
            if (desktopHDMinMedia.matches) {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsDesktopHD[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsDesktopHD[i]);
                    }
                    lazyLoadVideo.load();
                }

                // if the screen width is between 768px and 1919px (desktop)
            } else if (desktopMinMedia.matches && desktopMaxMedia.matches) {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsDesktop[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsDesktop[i]);
                    }
                    lazyLoadVideo.load();
                }

                // if either condition above is not met, default to mobile
            } else {
                if (e.type === "DOMContentLoaded") {
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].dataset.src = urlsMobile[i];
                    }
                // } else if (e.type === "resize") {
                //     if (lazyLoadVideo !== true) {
                //         lazyLoadVideo.removeAttribute("poster");
                //     }

                    lazyLoadVideo.pause();
                    for (let i = 0; i < lazyLoadVideo.children.length; i++) {
                        lazyLoadVideo.children[i].setAttribute("src", urlsMobile[i]);
                    }
                    lazyLoadVideo.load();
                }
            }
        };

        /* load event - sets data-srcs of video tag */
        setVideoSources(e);

        /* resize event - set <video> <source> tag(s) "src" attributes */
        window.addEventListener("resize", (e) => {
            setVideoSources(e);
        });

        const lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

        // if ("IntersectionObserver" in window) {
        //     const lazyVideoObserver = new IntersectionObserver(function (entries, observer) {
        //         entries.forEach(function (video) {
        //             if (video.isIntersecting) {
        //                 for (let source in video.target.children) {
        //                     const videoSource = video.target.children[source];
        //                     if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
        //                         videoSource.src = videoSource.dataset.src;
        //                     }
        //                 }

        //                 video.target.load();
        //                 video.target.classList.remove("lazy"); 
        //                 lazyVideoObserver.unobserve(video.target);
        //             }
        //         });
        //     });

        //     lazyVideos.forEach(function (lazyVideo) {
        //         lazyVideoObserver.observe(lazyVideo);
        //     });
        // }
    });



